import React, { useState, useRef, Suspense } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Html, PerspectiveCamera, useTexture, OrbitControls, Instances, Instance, Loader, Sampler, SpotLight,SpotLightShadow, MeshDistortMaterial, SoftShadows, useGLTF, Environment, Lightformer, BakeShadows } from '@react-three/drei'
import { useSpring, animated, config } from '@react-spring/three'
import { Bloom, DepthOfField, Autofocus, EffectComposer, Grid, Noise, Vignette } from '@react-three/postprocessing'
//import { useControls } from 'leva'
import { Perf } from 'r3f-perf'

import './App.css';
import logoAgate from './logo-spl-agate.png';
import logoAgglo from './nimes-metropole-site-web.png';
//import MarcheGare from './MarcheGare';

export default function App() {

  const AnimatedPerspectiveCamera = animated(PerspectiveCamera),
  AnimatedInstance = animated(Instance);


  // *************************************
  // DEFINITION CLICS
  // *************************************
  
  const [clicked2024, setClicked2024] = useState(true);
  function clickButton2024() {
    setClicked2024(h => true);
    setClicked2026(h => false);
    setClicked2027(h => false);
    setClicked2028(h => false);
    setClicked2030(h => false);
    setClicked2031(h => false);
    setClicked2032(h => false);
  }
  const [clicked2026, setClicked2026] = useState(false);
  function clickButton2026() {
    setClicked2024(h => false);
    setClicked2026(h => true);
    setClicked2027(h => false);
    setClicked2028(h => false);
    setClicked2030(h => false);
    setClicked2031(h => false);
    setClicked2032(h => false);
  }
  const [clicked2027, setClicked2027] = useState(false);
  function clickButton2027() {
    setClicked2024(h => false);
    setClicked2026(h => false);
    setClicked2027(h => true);
    setClicked2028(h => false);
    setClicked2030(h => false);
    setClicked2031(h => false);
    setClicked2032(h => false);
  }
  const [clicked2028, setClicked2028] = useState(false);
  function clickButton2028() {
    setClicked2024(h => false);
    setClicked2026(h => false);
    setClicked2027(h => false);
    setClicked2028(h => true);
    setClicked2030(h => false);
    setClicked2031(h => false);
    setClicked2032(h => false);
  }
  const [clicked2030, setClicked2030] = useState(false);
  function clickButton2030() {
    setClicked2024(h => false);
    setClicked2026(h => false);
    setClicked2027(h => false);
    setClicked2028(h => false);
    setClicked2030(h => true);
    setClicked2031(h => false);
    setClicked2032(h => false);
  }
  const [clicked2031, setClicked2031] = useState(false);
  function clickButton2031() {
    setClicked2024(h => false);
    setClicked2026(h => false);
    setClicked2027(h => false);
    setClicked2028(h => false);
    setClicked2030(h => false);
    setClicked2031(h => true);
    setClicked2032(h => false);
  }
  const [clicked2032, setClicked2032] = useState(false);
  function clickButton2032() {
    setClicked2024(h => false);
    setClicked2026(h => false);
    setClicked2027(h => false);
    setClicked2028(h => false);
    setClicked2030(h => false);
    setClicked2031(h => false);
    setClicked2032(h => true);
  }

  const [clickedChateauEau, setClickedChateauEau] = useState(false);
  function clickButtonChateauEau() {
    setClickedChateauEau(s => true);
    setClickedAbattoir(g => false);
    setClicked2026(h => false);
    setClickedHalles(i => false);
    setClickedFermeturePanneau(j => false);
  }

  const [clickedAbattoir, setClickedAbattoir] = useState(false);
  function clickButtonAbattoir() {
    setClickedChateauEau(s => false);
    setClickedAbattoir(g => true);
    setClicked2026(h => false);
    setClickedHalles(i => false);
    setClickedFermeturePanneau(j => false);
  }

  const [clickedHalles, setClickedHalles] = useState(false);
  function clickButtonHalles() {
    setClickedChateauEau(s => false);
    setClickedAbattoir(g => false);
    setClicked2026(h => false);
    setClickedHalles(i => true);
    setClickedFermeturePanneau(j => false);
  }

  const [clickedFermeturePanneau, setClickedFermeturePanneau] = useState(false);
  function clickFermeturePanneau() {
    setClickedChateauEau(s => false);
    setClickedAbattoir(g => false);
    setClicked2026(h => false);
    setClickedHalles(i => false);
    setClickedFermeturePanneau(j => true);
  }


  // *************************************
  // DEFINITION OVER
  // *************************************

  const [overChateauEau, setOverChateauEau] = useState(false);
  function pointerOverChateauEau() {
    setOverChateauEau(ge => true)
  }
  function pointerLeaveChateauEau() {
    setOverChateauEau(ge => false);
  }

  const [overAbattoirs, setOverAbattoirs] = useState(false);
  function pointerOverAbattoirs() {
    setOverAbattoirs(ge => true)
  }
  function pointerLeaveAbattoirs() {
    setOverAbattoirs(ge => false);
  }
  
  const [overHalles, setOverHalles] = useState(false);
  function pointerOverHalles() {
    setOverHalles(ge => true)
  }
  function pointerLeaveHalles() {
    setOverHalles(ge => false);
  }


  // *************************************
  // DEFINITION COULEURS
  // *************************************
  const colorBatimentsInterest = '#fff'
  const colorBatimentsInterestOver = '#ffe421'
  const colorBatimentsAutres = 'white'
  const colorRoutes = '#ccc'
  const colorEmprise = 'green'
  const colorGround = 'white'
  const colorBatimentsFutur = "#333"


  // *************************************
  // COMPONENT MARCHE GARE
  // *************************************

  const MarcheGare = (props) => {

    const { nodes, materials } = useGLTF('/marche-gare-v2.glb');

    return (
      <group {...props} dispose={null}>

        <group className="batimentsEmprise">

          <mesh castShadow receiveShadow
            geometry={nodes.ChateauEau.geometry}
            position={[1.579, 0.71, -0.602]}
            onClick={clickButtonChateauEau}
            onPointerEnter={pointerOverChateauEau}
            onPointerLeave={pointerLeaveChateauEau}
            >
            <meshStandardMaterial color={overChateauEau ? colorBatimentsInterestOver : colorBatimentsInterest} />
          </mesh>

          <animated.mesh castShadow receiveShadow
            geometry={nodes.Halles2024.geometry}
            position={springBatiment.positionHalles2024}
            onClick={clickButtonHalles}
            onPointerEnter={pointerOverHalles}
            onPointerLeave={pointerLeaveHalles}
            >
            <meshStandardMaterial color={overHalles ? colorBatimentsInterestOver : colorBatimentsInterest} />
          </animated.mesh>

          <animated.mesh castShadow receiveShadow
            geometry={nodes.Halles2024.geometry}
            position={springBatiment.positionHallesFutur}
            onClick={clickButtonHalles}
            onPointerEnter={pointerOverHalles}
            onPointerLeave={pointerLeaveHalles}
            >
            <meshStandardMaterial color={'red'} />
          </animated.mesh>

          <animated.mesh castShadow receiveShadow
            geometry={nodes.Cirque.geometry}
            position={springBatiment.positionCirque}
            >
            <meshStandardMaterial color={colorBatimentsInterest} />
          </animated.mesh>

          <mesh castShadow receiveShadow geometry={nodes.Reservoir.geometry} position={[-3.148, 0.519, 0.523]} scale={1.838} >
            <meshStandardMaterial color={colorBatimentsInterest} />
          </mesh>

          <Instances className="nouveauxBatiments" castShadow receiveShadow >
            <boxGeometry />
            <meshStandardMaterial color={colorBatimentsFutur} />
            <AnimatedInstance className="batimentA1a" position={springBatiment.positionBatimentA1a} scale={[.18,.08,.19]} />
            <AnimatedInstance className="batimentA1b" position={springBatiment.positionBatimentA1b} scale={[.18,.08,.19]} />
            <AnimatedInstance className="batimentA2" position={springBatiment.positionBatimentA2} scale={[.55,.08,.19]} />
            <AnimatedInstance className="batimentA3" position={springBatiment.positionBatimentA3} scale={[.15,.08,.35]} />
            <AnimatedInstance className="batimentB1a" position={springBatiment.positionBatimentB1a} scale={[.18,.08,.19]} />
            <AnimatedInstance className="batimentB1b" position={springBatiment.positionBatimentB1b} scale={[.18,.08,.19]} />
            <AnimatedInstance className="batimentB2a" position={springBatiment.positionBatimentB2a} scale={[.56,.08,.2]} />
            <AnimatedInstance className="batimentB2b" position={springBatiment.positionBatimentB2b} scale={[.18,.08,.24]} />
            <AnimatedInstance className="batimentC1a" position={springBatiment.positionBatimentC1a} scale={[.18,.08,.29]} />
            <AnimatedInstance className="batimentC1b" position={springBatiment.positionBatimentC1b} scale={[.18,.08,.29]} />
            <AnimatedInstance className="batimentC2a" position={springBatiment.positionBatimentC2a} scale={[.18,.08,.29]} />
            <AnimatedInstance className="batimentC2b" position={springBatiment.positionBatimentC2b} scale={[.18,.08,.29]} />
            <AnimatedInstance className="batimentC3" position={springBatiment.positionBatimentC3} scale={[.18,.08,.49]} />
            <AnimatedInstance className="batimentD1a" position={springBatiment.positionBatimentD1a} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentD1b" position={springBatiment.positionBatimentD1b} scale={[.29,.08,.18]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentD1c" position={springBatiment.positionBatimentD1c} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentF1" position={springBatiment.positionBatimentF1} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentF2" position={springBatiment.positionBatimentF2} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentF3" position={springBatiment.positionBatimentF3} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentF4" position={springBatiment.positionBatimentF4} scale={[.18,.08,.38]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentG1a" position={springBatiment.positionBatimentG1a} scale={[.18,.08,.46]} />
            <AnimatedInstance className="batimentG1b" position={springBatiment.positionBatimentG1b} scale={[.21,.08,.18]} />
            <AnimatedInstance className="batimentG2" position={springBatiment.positionBatimentG2} scale={[.18,.08,.46]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentG3" position={springBatiment.positionBatimentG3} scale={[.18,.08,.46]} rotation={[0,.18,0]} />
            <Instance className="batimentJ1" position={[-1.71,.54,-.61]} scale={[.28,.08,.36]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentK1" position={springBatiment.positionBatimentK1} scale={[.31,.08,.42]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentK2" position={springBatiment.positionBatimentK2} scale={[.31,.08,.42]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batimentK3" position={springBatiment.positionBatimentK3} scale={[.31,.08,.42]} rotation={[0,.18,0]} />
            <Instance className="batimentM1" position={[-3.06,.54,-.98]} scale={[.4,.08,.31]} rotation={[0,.18,0]} />
            <AnimatedInstance className="ombriere" position={springBatiment.positionOmbriere} scale={[.3,.08,.2]} />
            <AnimatedInstance className="parking1" position={springBatiment.positionParking1} scale={[.6,.08,.19]} />
            <AnimatedInstance className="parking2" position={springBatiment.positionParking2} scale={[.6,.08,.19]} />
            <AnimatedInstance className="batimentZ1a" position={springBatiment.positionBatimentZ1a} scale={[.6,.08,.2]} />
            <AnimatedInstance className="batimentZ1b" position={springBatiment.positionBatimentZ1b} scale={[.8,.08,.2]} />
            <AnimatedInstance className="batimentZ2a" position={springBatiment.positionBatimentZ2a} scale={[.18,.08,.37]} />
            <AnimatedInstance className="batimentZ2b" position={springBatiment.positionBatimentZ2b} scale={[.18,.08,.37]} />
            <AnimatedInstance className="batimentZ2c" position={springBatiment.positionBatimentZ2c} scale={[.38,.08,.19]} />
            <AnimatedInstance className="batimentZ4a" position={springBatiment.positionBatimentZ4a} scale={[.27,.08,.17]} />
            <AnimatedInstance className="batimentZ4b" position={springBatiment.positionBatimentZ4b} scale={[.19,.08,.29]} />
            <AnimatedInstance className="batimentZ4c" position={springBatiment.positionBatimentZ4c} scale={[.19,.08,.29]} />
            <AnimatedInstance className="batimentZ4d" position={springBatiment.positionBatimentZ4d} scale={[.27,.08,.17]} />
            <AnimatedInstance className="batimentZ5a" position={springBatiment.positionBatimentZ5a} scale={[.19,.08,.29]} />
            <AnimatedInstance className="batimentZ5b" position={springBatiment.positionBatimentZ5b} scale={[.19,.08,.29]} />
          </Instances>

          <Instances castShadow receiveShadow >
            <boxGeometry />
            <meshStandardMaterial color={colorBatimentsInterest} />
            <Instance className="abattoirs" position={[.97,.57,-.81]} scale={[.3,.15,.23]} />
            <Instance className="batiment01" position={[3.1,.52,.6]} scale={[.13,.06,.26]} rotation={[0,-.1,0]}/>
            <AnimatedInstance className="batiment02" position={springBatiment.positionBatiment02} scale={[.33,.13,.44]} />
            <AnimatedInstance className="batiment03" position={springBatiment.positionBatiment03} scale={[.31,.09,.49]} />
            <AnimatedInstance className="batiment04" position={springBatiment.positionBatiment04} scale={[.13,.06,.2]} />
            <AnimatedInstance className="batiment05" position={springBatiment.positionBatiment05} scale={[.28,.05,.28]} />
            <AnimatedInstance className="batiment06" position={springBatiment.positionBatiment06} scale={[.09,.05,.08]} />
            <AnimatedInstance className="batiment07" position={springBatiment.positionBatiment07} scale={[.13,.05,.1]} />
            <AnimatedInstance className="batiment08" position={springBatiment.positionBatiment08} scale={[.29,.05,.13]} />
            <AnimatedInstance className="batiment09" position={springBatiment.positionBatiment09} scale={[.11,.07,.09]} />
            <AnimatedInstance className="batiment10" position={springBatiment.positionBatiment10} scale={[.44,.07,.27]} />
            <AnimatedInstance className="batiment11" position={springBatiment.positionBatiment11} scale={[.26,.07,.27]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batiment12" position={springBatiment.positionBatiment12} scale={[.22,.07,.17]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batiment13" position={springBatiment.positionBatiment13} scale={[.09,.05,.11]} rotation={[0,.18,0]} />
            <Instance className="batiment15" position={[-3.13,.52,.75]} scale={[.45,.05,.21]} rotation={[0,.11,0]} />
            <Instance className="batiment16" position={[-3.42,.52,.55]} scale={[.14,.05,.09]} rotation={[0,.11,0]} />
            <Instance className="batiment17" position={[-3.91,.52,.56]} scale={[.14,.05,.08]} rotation={[0,.11,0]} />
            <Instance className="batiment18" position={[-4.68,.52,.80]} scale={[.71,.05,.46]} rotation={[0,.11,0]} />
            <Instance className="batiment19" position={[-5.44,.52,.52]} scale={[.43,.05,.25]} rotation={[0,.86,0]} />
            <Instance className="batiment20" position={[-4.22,.54,-.15]} scale={[1.07,.08,.54]} rotation={[0,.18,0]} />
            <Instance className="batiment21" position={[-2.62,.54,-.46]} scale={[1.16,.08,.56]} rotation={[0,.18,0]} />
            <Instance className="batiment22" position={[-2.18,.53,-1.16]} scale={[.55,.07,.29]} rotation={[0,.18,0]} />
            <Instance className="batiment23" position={[-1.73,.53,-.89]} scale={[.24,.07,.12]} rotation={[0,.18,0]} />
            <Instance className="batiment24" position={[-1.11,.53,-1.41]} scale={[.74,.07,.39]} rotation={[0,.18,0]} />
            <Instance className="batiment25" position={[-.8,.54,-.78]} scale={[1.32,.08,.36]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batiment26" position={springBatiment.positionBatiment26} scale={[.3,.07,.17]} rotation={[0,.18,0]} />
            <AnimatedInstance className="batiment27" position={springBatiment.positionBatiment27} scale={[.41,.1,.87]} />
            <AnimatedInstance className="batiment28" position={springBatiment.positionBatiment28} scale={[.84,.14,.19]} />
            <Instance className="batiment29" position={[1.33,.52,-.11]} scale={[.26,.05,.11]} />
            <AnimatedInstance className="batiment30" position={springBatiment.positionBatiment30} scale={[.23,.1,.46]} />
            <AnimatedInstance className="batiment31" position={springBatiment.positionBatiment31} scale={[.18,.1,.65]} />
            <Instance className="batiment32" position={[2.13,.54,-.7]} scale={[.22,.08,1.15]} />
            <AnimatedInstance className="batiment33" position={springBatiment.positionBatiment33} scale={[.31,.07,.2]} />
            <AnimatedInstance className="batiment34" position={springBatiment.positionBatiment34} scale={[.11,.07,.31]} />
            <AnimatedInstance className="batiment35" position={springBatiment.positionBatiment35} scale={[.13,.07,.1]} />
            <AnimatedInstance className="batiment36" position={springBatiment.positionBatiment36} scale={[.42,.07,.21]} />
          </Instances>
        </group>

        <group className="batimentsAutres">
          <Instances castShadow receiveShadow >
            <boxGeometry />
            <meshStandardMaterial color={colorBatimentsAutres} />
            <Instance className="batiment35bis" position={[3.59,.53,-.32]} scale={[.25,.07,.16]} rotation={[0,-.11,0]} />
            <Instance className="batiment36bis" position={[3.87,.53,.57]} scale={[.43,.07,.16]} rotation={[0,-.11,0]} />
            <Instance className="batiment37" position={[3.77,.53,.82]} scale={[.26,.07,.15]} rotation={[0,-.11,0]} />
            <Instance className="batiment38" position={[4.71,.53,.57]} scale={[.24,.07,.31]} rotation={[0,-.19,0]} />
            <Instance className="batiment39" position={[4.96,.53,.56]} scale={[.22,.07,.41]} rotation={[0,-.19,0]} />
            <Instance className="batiment40" position={[5.33,.53,.6]} scale={[.16,.07,.3]} rotation={[0,-.36,0]} />
            <Instance className="batiment41" position={[5.34,.53,-.11]} scale={[.61,.07,.44]} rotation={[0,-.19,0]} />
            <Instance className="batiment42" position={[5.51,.53,-.95]} scale={[.61,.07,.44]} rotation={[0,-.19,0]} />
            <Instance className="batiment43" position={[5.61,.53,-1.49]} scale={[.23,.07,.11]} rotation={[0,-.19,0]} />
            <Instance className="batiment44" position={[5.17,.53,-1.64]} scale={[.33,.07,.11]} rotation={[0,-.19,0]} />
            <Instance className="batiment45" position={[5.23,.53,-2.09]} scale={[.17,.07,.12]} rotation={[0,-.19,0]} />
            <Instance className="batiment46" position={[5.69,.53,-1.89]} scale={[.23,.07,.35]} rotation={[0,-.19,0]} />
            <Instance className="batiment47" position={[5.41,.53,-2.66]} scale={[.26,.07,.11]} rotation={[0,-.02,0]} />
            <Instance className="batiment48" position={[5.53,.53,-2.94]} scale={[.56,.07,.11]} rotation={[0,-.02,0]} />
            <Instance className="batiment49" position={[5.5,.53,-3.67]} scale={[.61,.07,.68]} rotation={[0,-.14,0]} />
            <Instance className="batiment50" position={[4.77,.53,-3.87]} scale={[.61,.07,.81]} rotation={[0,-.14,0]} />
            <Instance className="batiment51" position={[3.67,.53,-4.19]} scale={[.21,.07,.46]} rotation={[0,-.02,0]} />
            <Instance className="batiment52" position={[2.6,.53,-4.45]} scale={[.15,.07,.29]} rotation={[0,-.02,0]} />
            <Instance className="batiment53" position={[2.19,.53,-4.26]} scale={[.38,.07,.62]} rotation={[0,-.02,0]} />
            <Instance className="batiment54" position={[2.27,.53,-3.16]} scale={[.29,.07,.65]} rotation={[0,-.02,0]} />
            <Instance className="batiment55" position={[1.33,.53,-4.44]} scale={[.58,.07,.2]} rotation={[0,-.02,0]} />
            <Instance className="batiment56" position={[1.5,.53,-3.96]} scale={[.84,.07,.41]} rotation={[0,-.02,0]} />
            <Instance className="batiment57" position={[1.46,.53,-3.28]} scale={[.85,.07,.63]} rotation={[0,-.02,0]} />
            <Instance className="batiment58" position={[1.04,.53,-2.67]} scale={[1.64,.07,.21]} rotation={[0,-.02,0]} />
            <Instance className="batiment59" position={[.49,.53,-4.04]} scale={[.73,.07,.15]} rotation={[0,-.02,0]} />
            <Instance className="batiment60" position={[.59,.53,-3.23]} scale={[.41,.07,.53]} rotation={[0,-.02,0]} />
            <Instance className="batiment61" position={[-.24,.53,-3.94]} scale={[.44,.07,.38]} rotation={[0,-.02,0]} />
            <Instance className="batiment62" position={[-.18,.53,-3.31]} scale={[.53,.07,.61]} rotation={[0,-.02,0]} />
            <Instance className="batiment63" position={[-1.21,.53,-3.86]} scale={[1.21,.07,.38]} rotation={[0,-.02,0]} />
            <Instance className="batiment64" position={[-1.22,.53,-3.29]} scale={[1.22,.07,.36]} rotation={[0,-.02,0]} />
            <Instance className="batiment65" position={[-2.91,.53,-3.66]} scale={[.5,.07,.44]} rotation={[0,-.02,0]} />
            <Instance className="batiment66" position={[-3.54,.53,-3.74]} scale={[.24,.07,.21]} rotation={[0,-.02,0]} />
            <Instance className="batiment67" position={[-4.89,.53,-4.06]} scale={[.68,.07,.22]} rotation={[0,-.02,0]} />
            <Instance className="batiment68" position={[-4.77,.53,-3.45]} scale={[1.95,.07,.62]} rotation={[0,-.02,0]} />
            <Instance className="batiment69" position={[-4.4,.53,-2.83]} scale={[.32,.07,.19]} rotation={[0,-.02,0]} />
            <Instance className="batiment70" position={[-6.08,.53,-3.9]} scale={[1.09,.07,.22]} rotation={[0,.84,0]} />
            <Instance className="batiment71" position={[-6.12,.53,-3.47]} scale={[.19,.07,.24]} rotation={[0,-.02,0]} />
            <Instance className="batiment72" position={[-6.81,.53,-3.08]} scale={[.16,.07,.22]} rotation={[0,.84,0]} />
            <Instance className="batiment72bis" position={[-6.51,.53,-2.58]} scale={[.48,.07,.23]} rotation={[0,-.06,0]} />
            <Instance className="batiment73" position={[-5.93,.53,-2.38]} scale={[.12,.07,.3]} rotation={[0,-.06,0]} />
            <Instance className="batiment74" position={[-5.14,.53,-2.34]} scale={[.21,.07,.21]} rotation={[0,-.12,0]} />
            <Instance className="batiment75" position={[-5.9,.53,-1.9]} scale={[.08,.07,.23]} rotation={[0,-.06,0]} />
            <Instance className="batiment76" position={[-6.36,.53,-1.68]} scale={[.15,.07,.16]} rotation={[0,.26,0]} />
            <Instance className="batiment77" position={[-6.57,.53,-1.61]} scale={[.2,.07,.55]} rotation={[0,.26,0]} />
            <Instance className="batiment78" position={[-6.66,.53,-1.08]} scale={[.24,.07,.16]} rotation={[0,.26,0]} />
            <Instance className="batiment79" position={[-6.88,.53,-1.63]} scale={[.1,.07,.11]} rotation={[0,.26,0]} />
            <Instance className="batiment80" position={[-6.61,.53,-2.18]} scale={[.76,.07,.23]} rotation={[0,-.06,0]} />
            <Instance className="batiment81" position={[-6.98,.53,-2.61]} scale={[.08,.07,.23]} rotation={[0,-.06,0]} />
            <Instance className="batiment82" position={[-7.84,.53,-2.85]} scale={[.16,.07,.12]} rotation={[0,-.22,0]} />
            <Instance className="batiment83" position={[-8.12,.53,-2.93]} scale={[.16,.07,.12]} rotation={[0,-.22,0]} />
            <Instance className="batiment84" position={[-7.94,.53,-2.3]} scale={[.13,.07,.19]} rotation={[0,-.22,0]} />
            <Instance className="batiment84" position={[-8.3,.53,-1.98]} scale={[.06,.07,.11]} rotation={[0,.29,0]} />
            <Instance className="batiment85bis" position={[-8.13,.53,-1.99]} scale={[.14,.07,.13]} rotation={[0,-.22,0]} />
            <Instance className="batiment86" position={[-8.07,.53,-1.74]} scale={[.06,.07,.14]} rotation={[0,-.22,0]} />
            <Instance className="batiment87" position={[-8.28,.53,-1.54]} scale={[.26,.07,.07]} rotation={[0,-.22,0]} />
            <Instance className="batiment88" position={[-8.38,.53,-1.18]} scale={[.12,.07,.22]} rotation={[0,-.22,0]} />
            <Instance className="batiment89" position={[-7.99,.53,-.98]} scale={[.2,.07,.36]} rotation={[0,-.22,0]} />
            <Instance className="batiment90" position={[-5.97,.53,-.1]} scale={[.17,.07,.36]} rotation={[0,-.66,0]} />
            <Instance className="batiment91" position={[-5.86,.53,.11]} scale={[.23,.07,1.07]} rotation={[0,-.66,0]} />
            <Instance className="batiment93" position={[-6.72,.53,1.32]} scale={[.33,.07,.19]} rotation={[0,-.48,0]} />
            <Instance className="batiment94" position={[-7.11,.53,1.3]} scale={[.15,.07,.42]} rotation={[0,-.37,0]} />
            <Instance className="batiment95" position={[-7.9,.53,1.61]} scale={[.26,.07,.3]} rotation={[0,.11,0]} />
            <Instance className="batiment96" position={[-7.94,.53,2.94]} scale={[.07,.07,.66]} rotation={[0,-.13,0]} />
            <Instance className="batiment97" position={[-7.71,.53,2.7]} scale={[.08,.07,.47]} rotation={[0,-.12,0]} />
            <Instance className="batiment98" position={[-7.18,.53,2.36]} scale={[.40,.07,.09]} rotation={[0,-.01,0]} />
            <Instance className="batiment99" position={[-7.3,.53,3.14]} scale={[.33,.07,.16]} rotation={[0,-.16,0]} />
            <Instance className="batiment100" position={[-7.04,.53,2.95]} scale={[.1,.07,.29]} rotation={[0,-.32,0]} />
            <Instance className="batiment101" position={[-6.44,.53,2.7]} scale={[.34,.07,.16]} rotation={[0,-.17,0]} />
            <Instance className="batiment102" position={[-6.15,.53,2.57]} scale={[.12,.07,.16]} rotation={[0,-.17,0]} />
            <Instance className="batiment103" position={[-6.18,.53,3.48]} scale={[.12,.07,.15]} rotation={[0,-.26,0]} />
            <Instance className="batiment104" position={[-5.87,.53,3.47]} scale={[.17,.07,.11]} rotation={[0,-.26,0]} />
            <Instance className="batiment105" position={[-5.34,.53,3.47]} scale={[.23,.07,.24]} rotation={[0,-.26,0]} />
            <Instance className="batiment106" position={[-5.17,.53,3.15]} scale={[.17,.07,.15]} rotation={[0,-.26,0]} />
            <Instance className="batiment107" position={[-5.15,.53,2.86]} scale={[.31,.07,.08]} rotation={[0,-.26,0]} />
            <Instance className="batiment108" position={[-4.65,.53,3.1]} scale={[.16,.07,.15]} rotation={[0,-.26,0]} />
            <Instance className="batiment109" position={[-4.2,.53,3.26]} scale={[.52,.07,.3]} rotation={[0,-.26,0]} />
            <Instance className="batiment110" position={[-1.29,.53,1.5]} scale={[.74,.07,.3]} rotation={[0,.04,0]} />
            <Instance className="batiment111" position={[-.34,.53,1.93]} scale={[.27,.07,.45]} rotation={[0,-.11,0]} />
            <Instance className="batiment112" position={[.06,.53,1.76]} scale={[.19,.07,.67]} rotation={[0,-.11,0]} />
            <Instance className="batiment113" position={[.65,.53,1.58]} scale={[.69,.07,.28]} rotation={[0,-.01,0]} />
            <Instance className="batiment114" position={[.7,.53,2.07]} scale={[.63,.07,.36]} rotation={[0,-.11,0]} />
            <Instance className="batiment115" position={[-.45,.53,3.06]} scale={[.29,.07,.45]} rotation={[0,-.06,0]} />
            <Instance className="batiment116" position={[.23,.53,3.2]} scale={[.88,.07,1.03]} rotation={[0,-.06,0]} />
            <Instance className="batiment117" position={[.24,.53,3.97]} scale={[.76,.07,.11]} rotation={[0,-.48,0]} />
            <Instance className="batiment118" position={[1.87,.53,1.63]} scale={[.49,.07,.22]} rotation={[0,-.2,0]} />
            <Instance className="batiment119" position={[3.01,.53,1.79]} scale={[1.53,.07,.3]} rotation={[0,-.25,0]} />
            <Instance className="batiment120" position={[2.15,.53,1.95]} scale={[.23,.07,.18]} rotation={[0,-.11,0]} />
            <Instance className="batiment121" position={[2.36,.53,2.16]} scale={[.2,.07,.15]} rotation={[0,-.11,0]} />
            <Instance className="batiment122" position={[1.66,.53,2.11]} scale={[.59,.07,.16]} rotation={[0,-.11,0]} />
            <Instance className="batiment123" position={[1.68,.53,2.41]} scale={[.48,.07,.15]} rotation={[0,-.11,0]} />
            <Instance className="batiment124" position={[1.4,.53,3.04]} scale={[.21,.07,.52]} rotation={[0,-.22,0]} />
            <Instance className="batiment125" position={[1.74,.53,3.11]} scale={[.25,.07,.63]} rotation={[0,-.22,0]} />
            <Instance className="batiment126" position={[2.15,.53,3.2]} scale={[.25,.07,.67]} rotation={[0,-.22,0]} />
            <Instance className="batiment127" position={[4.74,.53,1.48]} scale={[1.04,.07,.3]} rotation={[0,.02,0]} />
            <Instance className="batiment128" position={[5.55,.53,1.49]} scale={[.36,.07,.34]} rotation={[0,.02,0]} />
            <Instance className="batiment129" position={[4.01,.53,2.5]} scale={[.42,.07,.57]} rotation={[0,-.31,0]} />
            <Instance className="batiment130" position={[4.49,.53,2.26]} scale={[.18,.07,.47]} rotation={[0,-.31,0]} />
            <Instance className="batiment131" position={[5.19,.53,2.27]} scale={[.75,.07,.21]} rotation={[0,.49,0]} />
            <Instance className="batiment133" position={[5.74,.53,2.12]} scale={[.2,.07,.26]} rotation={[0,.49,0]} />
            <Instance className="batiment134" position={[4.06,.53,3.69]} scale={[.85,.07,.66]} rotation={[0,-.18,0]} />
            <Instance className="batiment135" position={[5.02,.53,3.87]} scale={[.85,.07,.66]} rotation={[0,-.18,0]} />
            <Instance className="batiment136" position={[5.69,.53,3.79]} scale={[.43,.07,1.04]} rotation={[0,-.18,0]} />
          </Instances>
        </group>
        
        <group className="voiesSecondaires">
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Autoroute.geometry}
            material={nodes.Autoroute.material}
            position={[-4.995, 0.579, 1.69]}
            rotation={[0, -0.529, 0]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Autoroute02.geometry}
            material={nodes.Autoroute02.material}
            position={[-10.515, 0.496, -1.538]}
            rotation={[0, -0.529, 0]}
            >
            <meshStandardMaterial color={colorGround} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Autoroute03.geometry}
            material={nodes.Autoroute03.material}
            position={[-8.7, 0.496, -0.477]}
            rotation={[0, -0.529, 0]}
            >
            <meshStandardMaterial color={colorGround} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Autoroute01.geometry}
            material={nodes.Autoroute01.material}
            position={[-12.451, 0.496, -2.67]}
            rotation={[0, -0.529, 0]}
            >
            <meshStandardMaterial color={colorGround} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Trains_01.geometry}
            material={nodes.Trains_01.material}
            position={[12.035, 0.5, 0.055]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Bas_04.geometry}
            material={nodes.Routes_Bas_04.material}
            position={[1.218, 0.5, 1.856]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Bas_03.geometry}
            material={nodes.Routes_Bas_03.material}
            position={[-4.514, 0.5, 2.412]}
            rotation={[0, -0.536, 0]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Bas_01.geometry}
            material={nodes.Routes_Bas_01.material}
            position={[-7.899, 0.5, 3.306]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Bas_02.geometry}
            material={nodes.Routes_Bas_02.material}
            position={[-6.795, 0.5, 2.978]}
            rotation={[0, -0.33, 0]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Bas_05.geometry}
            material={nodes.Routes_Bas_05.material}
            position={[4.507, 0.5, 4.224]}
            rotation={[0, -0.179, 0]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Routes_Milieu.geometry}
            material={nodes.Routes_Milieu.material}
            position={[0.655, 0.5, 0.008]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Trains_02.geometry}
            material={nodes.Trains_02.material}
            position={[-6.886, 0.5, 2.902]}
            rotation={[0, -0.333, 0]}
            scale={[1, 0.002, 1]}
            >
            <meshStandardMaterial color={colorRoutes} />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Autoroute04ter.geometry}
            material={nodes.Autoroute04ter.material}
            position={[-2.056, 0.548, 1.77]}
            rotation={[0, -0.529, 0]}
            >
            <meshStandardMaterial color={colorGround} />
          </mesh>
        </group>

      
      <mesh castShadow receiveShadow geometry={nodes.Emprise.geometry} position={[-2.252, 0.423, -0.103]} >
        <meshStandardMaterial color={colorEmprise} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Ground.geometry} position={[-0.384, 0.438, -0.442]} >
        <meshStandardMaterial color={colorGround} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Route_principale.geometry} position={[4.862, 0.5, -0.127]} scale={[1, 0.002, 1]} >
        <meshStandardMaterial color={colorRoutes} />
      </mesh>

    </group>
    );
  };
  useGLTF.preload('/marche-gare-v2.glb');
  

  // *************************************
  // DEFINITION SPRING
  // *************************************

  const springCamera = useSpring({
    positionCamera:
      clickedChateauEau ? [4,2,4] :
        clickedAbattoir ? [4,2,4] :
          /*clicked2026 ? [9,4,13] :
          clicked2027 ? [-6,4,13] :
          clicked2028 ? [-12,4,7] :
          clicked2030 ? [-14,4,-2] :
          clicked2031 ? [-11,4,-12] :
          clicked2032 ? [3,4,-15] :*/
            clickedHalles ? [5,2,5] :
              clickedFermeturePanneau ? [19,4,5] : [19,4,5],
    positionScene:
      clickedChateauEau ? [-1.58,-.6,.6] :
        clickedAbattoir ? [-0.98,-.6,.81] :
          (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [-.23,-.6,.56] :
            clickedHalles ? [-2.66,-.6,.65] :
              clickedFermeturePanneau ? [1.47,-.6,.32] : [1.47,-.6,.32],
    config: {
      mass: 5,
      tension: 120,
      friction: 100,
    },
  })

  const springBatiment = useSpring({
    positionHalles2024: clickedHalles ? [2.647, .35, -0.679] : [2.647, .55, -0.679],
    positionHallesFutur: clickedHalles ? [2.647, .55, -0.679] : [2.647, .35, -0.679],
    positionBatiment02: (clicked2024 || clicked2026 || clicked2027) ? [2.5,.56,.53] : [2.5,.36,.53],
    positionBatiment03: (clicked2024 || clicked2026 || clicked2027) ? [1.9,.54,.52] : [1.9,.34,.52],
    positionBatiment04: clicked2024 ? [1.36,.52,.67] : [1.36,.32,.67],
    positionBatiment05: clicked2024 ? [.87,.52,.53] : [.87,.32,.53],
    positionBatiment06: clicked2024 ? [.6,.52,.45] : [.6,.32,.45],
    positionBatiment07: clicked2024 ? [.41,.52,.47] : [.41,.32,.47],
    positionBatiment08: clicked2024 ? [.49,.52,.65] : [.49,.32,.65],
    positionBatiment09: clicked2024 ? [.27,.53,.56] : [.27,.33,.56],
    positionBatiment10: (clicked2024 || clicked2026 || clicked2027 || clicked2028 || clicked2030) ? [-.04,.53,.47] : [-.04,.33,.47],
    positionBatiment11: (clicked2024 || clicked2026 || clicked2027 || clicked2028 || clicked2030) ? [-.58,.53,.41] : [-.58,.33,.41],
    positionBatiment12: (clicked2024 || clicked2026 || clicked2027 || clicked2028 || clicked2030) ? [-.58,.53,.14] : [-.58,.33,.14],
    positionBatiment13: clicked2024 ? [-1.89,.52,.57] : [-1.89,.32,.57],
    positionBatiment26 : (clicked2024 || clicked2026 || clicked2027 || clicked2028) ? [-.38,.53,-1.23] : [-.38,.33,-1.23],
    positionBatiment27: (clicked2024 || clicked2026) ? [.27,.55,-.69] : [.27,.35,-.69],
    positionBatiment28: (clicked2024 || clicked2026) ? [.48,.56,-.14] : [.48,.36,-.14],
    positionBatiment30 : (clicked2024 || clicked2026 || clicked2027) ? [1.33,.55,-.77] : [1.33,.35,-.77],
    positionBatiment31: clicked2024 ? [1.85,.55,-.9] : [1.85,.35,-.9],
    positionBatiment33: (clicked2024 || clicked2026 || clicked2027 || clicked2028) ? [2.83,.53,-1.48] : [2.83,.33,-1.48],
    positionBatiment34: (clicked2024 || clicked2026 || clicked2027 || clicked2028) ? [2.9,.53,-1.76] : [2.9,.33,-1.76],
    positionBatiment35: (clicked2024 || clicked2026 || clicked2027 || clicked2028) ? [1.79,.53,-1.93] : [1.79,.33,-1.93],
    positionBatiment36: (clicked2024 || clicked2026 || clicked2027 || clicked2028) ? [1.68,.53,-1.68] : [1.68,.33,-1.68],
    positionBatimentA1a: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.43,.54,-1.14] : [.43,.34,-1.14],
    positionBatimentA1b: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.43,.54,-.89] : [.43,.34,-.89],
    positionBatimentA2: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1,.54,-1.14] : [1,.34,-1.14],
    positionBatimentA3: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.2,.54,-.87] : [1.2,.34,-.87],
    positionBatimentB1a: (clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.43,.54,-.39] : [.43,.34,-.39],
    positionBatimentB1b: (clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.43,.54,-.14] : [.43,.34,-.14],
    positionBatimentB2a: (clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.01,.54,-.4] : [1.01,.34,-.4],
    positionBatimentB2b: (clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.82,.54,-.17] : [.82,.34,-.17],
    positionBatimentC1a: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.18,.54,.27] : [1.18,.34,.27],
    positionBatimentC1b: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.18,.54,.62] : [1.18,.34,.62],
    positionBatimentC2a: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.82,.54,.27] : [.82,.34,.27],
    positionBatimentC2b: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.82,.54,.62] : [.82,.34,.62],
    positionBatimentC3: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [.43,.54,.52] : [.43,.34,.52],
    positionBatimentD1a: (clicked2030 || clicked2031 || clicked2032) ? [.11,.54,-1.54] : [.11,.34,-1.54],
    positionBatimentD1b: (clicked2030 || clicked2031 || clicked2032) ? [-.18,.54,-1.59] : [-.18,.34,-1.59],
    positionBatimentD1c: (clicked2030 || clicked2031 || clicked2032) ? [-.43,.54,-1.44] : [-.43,.34,-1.44],
    positionBatimentF1: (clicked2030 || clicked2031 || clicked2032) ? [.05,.54,-.24] : [.05,.34,-.24],
    positionBatimentF2: (clicked2030 || clicked2031 || clicked2032) ? [-.34,.54,-.17] : [-.34,.34,-.17],
    positionBatimentF3: (clicked2030 || clicked2031 || clicked2032) ? [-.73,.54,-.1] : [-.73,.34,-.1],
    positionBatimentF4: (clicked2030 || clicked2031 || clicked2032) ? [-1.11,.54,-.03] : [-1.11,.34,-.03],
    positionBatimentG1a: (clicked2031 || clicked2032) ? [-.17,.54,.41] : [-.17,.34,.41],
    positionBatimentG1b: (clicked2031 || clicked2032) ? [.07,.54,.27] : [.07,.34,.27],
    positionBatimentG2: (clicked2031 || clicked2032) ? [-.58,.54,.46] : [-.58,.34,.46],
    positionBatimentG3: (clicked2031 || clicked2032) ? [-1.01,.54,.54] : [-1.01,.34,.54],
    positionBatimentK1: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [-1.58,.54,.43] : [-1.58,.34,.43],
    positionBatimentK2: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [-2.01,.54,.51] : [-2.01,.34,.51],
    positionBatimentK3: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [-2.44,.54,.59] : [-2.44,.34,.59],
    positionOmbriere: (clicked2030 || clicked2031 || clicked2032) ? [2.78,.54,-1.42] : [2.78,.34,-1.42],
    positionParking1: (clicked2030 || clicked2031 || clicked2032) ? [.64,.54,-1.72] : [.64,.34,-1.72],
    positionParking2: (clicked2030 || clicked2031 || clicked2032) ? [.64,.54,-1.47] : [.64,.34,-1.47],
    positionBatimentZ1a: (clicked2030 || clicked2031 || clicked2032) ? [1.94,.54,-1.69] : [1.94,.34,-1.69],
    positionBatimentZ1b: (clicked2030 || clicked2031 || clicked2032) ? [2.72,.54,-1.69] : [2.72,.34,-1.69],
    positionBatimentZ2a: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.73,.54,-.86] : [1.73,.34,-.86],
    positionBatimentZ2b: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.73,.54,-.3] : [1.73,.34,-.3],
    positionBatimentZ2c: (clicked2026 || clicked2027 || clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.83,.54,-1.14] : [1.83,.34,-1.14],
    positionBatimentZ4a: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.97,.54,.21] : [1.97,.34,.21],
    positionBatimentZ4b: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.72,.54,.27] : [1.72,.34,.27],
    positionBatimentZ4c: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.72,.54,.62] : [1.72,.34,.62],
    positionBatimentZ4d: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [1.97,.54,.68] : [1.97,.34,.68],
    positionBatimentZ5a: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [2.43,.54,.27] : [2.43,.34,.27],
    positionBatimentZ5b: (clicked2028 || clicked2030 || clicked2031 || clicked2032) ? [2.43,.54,.62] : [2.43,.34,.62],
    positionCirque: clicked2024 ? [-2.35, 0.579, 0.645] : [-2.35, 0.379, 0.645],
    config: {
      mass: 30,
      tension: 120,
      friction: 100,
    },
  })


  // *************************************
  // DEFINITION LEVA
  // *************************************
  /*const {
    positionInstance,
    scaleInstance,
    rotationInstance
  } = useControls({
    positionInstance: {
      value: [19,4,5],
      step: 0.1,
    },
    scaleInstance: {
      value: [1,.08,1],
      step: 0.01,
    },
    rotationInstance: {
      value: [0,0,0],
      step: 0.01,
    }
  });*/


  // *************************************
  // OUTPUT
  // *************************************
  return (
    <div className="App">
      <Canvas shadows colormanagement="true" shadowmap="true">
        <Suspense fallback={null}>
        {/*
        <Perf position="top-left" />
        <fog attach="fog" color="black" near={levanear} far={levafar} />
        <EffectComposer>
          <DepthOfField
            focusDistance={levafocusDistance} // where to focus
            focalLength={levafocalLength} // focal length
            bokehScale={levabokehScale} // bokeh size
          />
        </EffectComposer>
        <axesHelper />
        */}
          <animated.group position={/*positionInstance*/springCamera.positionScene} >
            <MarcheGare />
            <ambientLight color="white" intensity={3} />
            <directionalLight color="white" position={[-9.5,6,0]} intensity={5} castShadow />
            {/*
            <pointLight color="lightblue" position={[2.5,0.3,-1]} intensity={1} distance={.5} decay={1}>
              <axesHelper />
            </pointLight>
            <rectAreaLight color="red" position={[0,0,6]} intensity={.4} rotation={[0,-0.4,0]} />
            <rectAreaLight color="blue" position={[0,0,-5]} intensity={.4} rotation={[0,2.7,0]} />
            <directionalLight color="white" position={[-2, 2, 4]} intensity={1} castShadow />
            */}
          </animated.group>
          <Html fullscreen>
            <div id="elementsAround">
              <div id="titre">
                <h1>Marché Gare</h1>
                <p>2024-2032</p>
                <p>NOUVELLE ZONE D’ACTIVITÉS<br/>ÉCONOMIQUES DURABLE<br/>À NÎMES.</p>
              </div>
              <div id="maquetteInteractive">
                <div><div></div><div></div></div>
                <p>MAQUETTE INTERACTIVE</p>
                <p>Cliquez sur les bâtiments de couleur,<br/>sur les éléments de la liste à droite<br/>ou sur la timeline au centre-bas<br/>pour plus d'informations.</p>
              </div>
              <div id="trait"></div>
              <div id="timeline">
                <div className="active" onClick={clickButton2024}>
                  <p>2024 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2026}>
                  <p>2026 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2027}>
                  <p>2027 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2028}>
                  <p>2028 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2030}>
                  <p>2030 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2031}>
                  <p>2031 <span>&raquo;</span></p>
                </div>
                <div onClick={clickButton2032}>
                  <p>2032 <span>&raquo;</span></p>
                </div>
              </div>
              <div id="logos">
                <img src={logoAgglo} alt="Logo Nîmes Métropole"/>
                <img src={logoAgate} alt="Logo SPL AGATE" />
              </div>
              <div id="listeBatiments">
                <p
                  id="buttonChateauEau"
                  className={overChateauEau ? 'active' : ''}
                  onClick={clickButtonChateauEau}
                  onPointerEnter={pointerOverChateauEau}
                  onPointerLeave={pointerLeaveChateauEau}
                ><span>CHÂTEAU D'EAU</span></p>
                <p
                  id="buttonAbattoir"
                  className={overAbattoirs ? 'active' : ''}
                  onClick={clickButtonAbattoir}
                  onPointerEnter={pointerOverAbattoirs}
                  onPointerLeave={pointerLeaveAbattoirs}
                ><span>ANCIENS ABATTOIRS</span></p>
                <p
                  id="buttonHalles"
                  className={overHalles ? 'active' : ''}
                  onClick={clickButtonHalles}
                  onPointerEnter={pointerOverHalles}
                  onPointerLeave={pointerLeaveHalles}
                ><span>HALLES</span></p>
                <p><span>BÂTIMENT 1</span></p>
                <p><span>BÂTIMENT 2</span></p>
                <p><span>BÂTIMENT 3</span></p>
                <p><span>BÂTIMENT 4</span></p>
              </div>
              <a id="retourAgate" href="https://spl-agate.com/" target="_blank"><p>retour sur le site SPL AGATE &raquo;</p></a>
            </div>
            <div id="panneauLeftChateauEau" className="panneauLeft" style={ clickedChateauEau ? {transform:'translateX(0)'} : {transform:'translateX(-100%)'} }>
              <div className="boutonFermeturePanneau" onClick={clickFermeturePanneau} ><img src="https://plateforme10-interactive-map.vercel.app/images/icn-close.svg" /></div>
              <img src="https://spl-agate.com/wp-content/uploads/visite_elus_culture_marche_gare_nimes-20_juillet_2021-04.jpg" />
              <h2>Chateau d'eau</h2>
              <p><b>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</b></p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
            <div id="panneauLeftAbattoir" className="panneauLeft" style={ clickedAbattoir ? {transform:'translateX(0)'} : {transform:'translateX(-100%)'} }>
              <div className="boutonFermeturePanneau" onClick={clickFermeturePanneau} ><img src="https://plateforme10-interactive-map.vercel.app/images/icn-close.svg" /></div>
              <img src="https://spl-agate.com/wp-content/uploads/visite_elus_culture_marche_gare_nimes-20_juillet_2021-03.jpg" />
              <h2>Anciens abattoirs</h2>
              <p><b>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</b></p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
            <div id="panneauLeftHalles" className="panneauLeft" style={ clickedHalles ? {transform:'translateX(0)'} : {transform:'translateX(-100%)'} }>
              <div className="boutonFermeturePanneau" onClick={clickFermeturePanneau} ><img src="https://plateforme10-interactive-map.vercel.app/images/icn-close.svg" /></div>
              <img src="https://spl-agate.com/wp-content/uploads/atelier_participatif_marche_gare_nimes-21_juillet_2021-02.jpg" />
              <h2>Halles</h2>
              <p><b>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</b></p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
          </Html>
          <OrbitControls
            autoRotate={true}
            autoRotateSpeed={.2}
            enableZoom={true}
            enableRotate={true}
            /*enableZoom={false}
            enableRotate={false}
            enableDamping="true"
            dampingFactor={1}*/
          />
          <SoftShadows />

          {/*
          <BakeShadows />
          <Environment
            preset="sunset" // apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse
            blur={.1}
            background
          />
          */}

          <AnimatedPerspectiveCamera makeDefault position={/*positionInstance*/springCamera.positionCamera} fov={10}/>
        
        </Suspense>
      </Canvas>
      <Loader dataInterpolation={(p) => `Chargement : ${p.toFixed(0)}%` /* Règle le nombre de chiffres après la virgule */ }/>
    </div>
  );
}
